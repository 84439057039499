export const AUTH_KEY = 'api_key';
export const ReferCode = 'referCode';

export const localStorageKey = {
  LoginBackUrl: 'loginBackUrl',
  reconnecting: 'reconnecting',
};

export const AuthMessages = {
  NotFoundCampaign: 'We can not find this campaign on your account.',
  NotFoundWorkspace: 'You must provide the workspace parameter.',
};

export const viewsConfig = {
  DenyReviewModal: 'deny_review_modal'
}
