import axios from 'axios/lib/axios';
import { isNil } from 'utils/lodash.util';

const RestClient = axios.create({
  baseURL: process.env.NEXT_APP_BASE_URL,
  // timeout: 10000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const customRestClient = (API_KEY) =>
  axios.create({
    timeout: 10000,
    headers: {
      Authorization: `Basic ${Buffer.from(API_KEY).toString('base64')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

export const setRestAuth = (API_KEY) => {
  if (!isNil(API_KEY)) {
    RestClient.defaults.headers.common['authorization'] = `Basic ${Buffer.from(API_KEY).toString(
      'base64',
    )}`;
  }
};

export const deleteAuthorization = () => {
  delete RestClient?.defaults?.headers?.common?.Authorization;
  delete RestClient?.defaults?.headers?.common['authorization'];
};

export default RestClient;
