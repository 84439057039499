import RestClient from './rest-client';
import { toaster } from 'evergreen-ui';
import { get } from 'utils/lodash.util';
import cookie from 'js-cookie';
import { AUTH_KEY } from 'constants/auth.constant';
import { deleteAuthorization } from 'services/rest-client';
import Router from 'next/router';
import queryString from 'query-string';

const USER_URL = '/users';
const WORKSPACE_ACCESS = 'workspace-access';

const getUserInfo = async () => {
  try {
    const response = await RestClient.get(`${USER_URL}`);
    return response;
  } catch (error) {
    if (get(error, ['response', 'data', 'statusCode']) === 401 && typeof window !== 'undefined') {
      cookie.remove(AUTH_KEY);
      deleteAuthorization();
      Router.push('/login');
    }
    const errorMess = get(error, ['response', 'data', 'message']);
    typeof window !== 'undefined' && errorMess && toaster?.danger(errorMess);
  }
};

const signUp = async (userData) => {
  try {
    const response = await RestClient.post(`${USER_URL}`, userData);
    return response.data;
  } catch (error) {
    typeof window !== 'undefined' && toaster.danger(get(error, ['response', 'data', 'message']));
  }
};

const invites = async (payload) => {
  try {
    const response = await RestClient.post(`${USER_URL}/invites`, payload);
    return response.data;
  } catch (error) {
    typeof window !== 'undefined' && toaster.danger(get(error, ['response', 'data', 'message']));
  }
};

const getInviteDetail = async (inviteId) => {
  try {
    const response = await RestClient.get(`${USER_URL}/invites/${inviteId}`);
    return response.data;
  } catch (error) {
    typeof window !== 'undefined' && toaster.danger(get(error, ['response', 'data', 'message']));
  }
};

const resendInvite = async (inviteId) => {
  try {
    const response = await RestClient.post(`${USER_URL}/invites/${inviteId}/resend`);
    return response.data;
  } catch (error) {
    typeof window !== 'undefined' && toaster.danger(get(error, ['response', 'data', 'message']));
  }
};

const deleteInvite = async (inviteId) => {
  try {
    const response = await RestClient.delete(`${USER_URL}/invites/${inviteId}`);
    return response.data;
  } catch (error) {
    typeof window !== 'undefined' && toaster.danger(get(error, ['response', 'data', 'message']));
  }
};

const getTeammates = async () => {
  try {
    const response = await RestClient.get(`${USER_URL}/teammates`);
    return response.data;
  } catch (error) {
    typeof window !== 'undefined' && toaster.danger(get(error, ['response', 'data', 'message']));
  }
};

const getTeammateAccess = async (workspaceId) => {
  try {
    const response = await RestClient.get(`${USER_URL}/teammates?access=${workspaceId}`);
    return response.data;
  } catch (error) {
    typeof window !== 'undefined' && toaster.danger(get(error, ['response', 'data', 'message']));
  }
};

const getAffiliates = async (params) => {
  try {
    const response = await RestClient.get(`/affiliates?${queryString.stringify(params)}`);
    return response.data;
  } catch (error) {
    typeof window !== 'undefined' && toaster.danger(get(error, ['response', 'data', 'message']));
  }
};

const verify = async (code) => {
  try {
    const response = await RestClient.post(`${USER_URL}/verify`, { code });
    return response.data;
  } catch (error) {
    typeof window !== 'undefined' && toaster.danger(get(error, ['response', 'data', 'message']));
  }
};

const resendEmail = async () => {
  try {
    const response = await RestClient.get(`${USER_URL}/verify/resend`);
    return response.data;
  } catch (error) {
    typeof window !== 'undefined' && toaster.danger(get(error, ['response', 'data', 'message']));
  }
};

const updateUser = async (payload) => {
  try {
    const response = await RestClient.put(`${USER_URL}`, payload);
    return response;
  } catch (error) {
    const errorMess = get(error, ['response', 'data', 'message']);
    typeof window !== 'undefined' && toaster.danger(errorMess);
  }
};

const resetPassword = async (payload) => {
  try {
    const response = await RestClient.post(`${USER_URL}/reset-password`, payload);
    return response.data;
  } catch (error) {
    typeof window !== 'undefined' && toaster.danger(get(error, ['response', 'data', 'message']));
  }
};

const addMemberWorkspaceAccess = async (payload) => {
  try {
    const response = await RestClient.post(`${USER_URL}/${WORKSPACE_ACCESS}`, payload);
    return response.data;
  } catch (error) {
    typeof window !== 'undefined' && toaster.danger(get(error, ['response', 'data', 'message']));
  }
};

const updateWorkspaceAccess = async (payload) => {
  try {
    const response = await RestClient.put(`${USER_URL}/${WORKSPACE_ACCESS}`, payload);
    return response.data;
  } catch (error) {
    typeof window !== 'undefined' && toaster.danger(get(error, ['response', 'data', 'message']));
  }
};

const revokeUserWorkspaceAccess = async (payload) => {
  try {
    const response = await RestClient.delete(`${USER_URL}/${WORKSPACE_ACCESS}`, { data: payload });
    return response.data;
  } catch (error) {
    typeof window !== 'undefined' && toaster.danger(get(error, ['response', 'data', 'message']));
  }
};

const revokeUser = async (payload) => {
  try {
    const response = await RestClient.delete(`${USER_URL}/revoke`, { data: payload });
    return response.data;
  } catch (error) {
    typeof window !== 'undefined' && toaster.danger(get(error, ['response', 'data', 'message']));
  }
};
const UserService = {
  getUserInfo,
  signUp,
  verify,
  resendEmail,
  updateUser,
  resetPassword,
  resendInvite,
  invites,
  getTeammates,
  deleteInvite,
  getInviteDetail,
  getAffiliates,
  addMemberWorkspaceAccess,
  updateWorkspaceAccess,
  revokeUserWorkspaceAccess,
  revokeUser,
  getTeammateAccess,
};

export default UserService;
