export const PlanTypes = {
  Trial: 'active_trial',
  DeadTrial: 'dead_trial',
  Inactive: 'inactive',
  Paid: 'paid',
};

export const UserPermissions = {
  User: 'user',
  Admin: 'admin',
  SuperAdmin: 'super_admin',
};

export const UserMode = {
  Single: 'single',
  Agency: 'agency',
};
