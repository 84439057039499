import App from 'next/app';
import { AppProvider } from 'contexts/app.context';
import { setRestAuth } from 'services/rest-client';
import { AUTH_KEY } from 'constants/auth.constant';
import { parseCookies } from 'utils/parseCookies.util';
import { IntercomProvider } from 'react-use-intercom';

import 'styles/globals.css';
import 'react-quill/dist/quill.bubble.css';

const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID;

function MyApp({ Component, pageProps, token }) {
  return (
    <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
      <AppProvider token={token}>
        <Component {...pageProps} />
      </AppProvider>
    </IntercomProvider>
  );
}

export default MyApp;

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  const {
    ctx: { req, res },
  } = appContext;

  const cookies = parseCookies(req);
  let accessToken = '';
  try {
    accessToken = cookies && cookies[AUTH_KEY];
    accessToken && setRestAuth(accessToken);
  } catch (error) {
    console.log(error);
  }

  return { ...appProps, token: accessToken };
};
