import { useEffect, useState } from 'react';
import cookie from 'js-cookie';
import { useRouter } from 'next/router';
import { find, get } from 'utils/lodash.util';
import { AUTH_KEY } from 'constants/auth.constant';
import { setRestAuth } from 'services/rest-client';
import UserService from 'services/user.service';
import WorkspaceService from 'services/workspace.service';
import { isFunction } from 'utils/lodash.util';
import { deleteAuthorization } from 'services/rest-client';
import { PlanTypes } from 'constants/user.constant';
import { useIntercom } from 'react-use-intercom';

const UserContext = (token) => {
  const router = useRouter();
  const { update } = useIntercom();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState({});
  const [loadingWorkspace, setLoadingWorkspace] = useState(false);
  const [workspaces, setWorkspaces] = useState([]);

  token && setRestAuth(token);

  const logOut = () => {
    cookie.remove(AUTH_KEY);
    deleteAuthorization();
    localStorage.clear();
    setUser({});
    setIsLoggedIn(false);
    setWorkspaces([]);
  };

  useEffect(() => {
    if (token) {
      configureAuth(token);
      getWorkspaces();
    } else {
      setIsLoggedIn(false);
    }
  }, [token]);

  const configureAuth = async () => {
    const user = await UserService.getUserInfo();
    if (user && user.data) {
      if (
        get(user, ['data', 'plan']) === PlanTypes.DeadTrial &&
        !['/settings/refer-a-friend', '/trial-over'].includes(router.pathname)
      ) {
        router.push('/trial-over');
      }
      const userName = `${get(user, 'data.first_name', '')} ${get(user, 'data.last_name', '')}`;
      const configBootProps = {
        name: userName,
        email: get(user, 'data.email'),
        createdAt: get(user, 'data.created'),
        userId: get(user, 'data.api_key'),
        customAttributes: {
          team_key: get(user, 'data.team_key'),
          api_key: get(user, 'data.api_key'),
        },
      };
      update({ ...configBootProps });
      setUser(user.data);
      setIsLoggedIn(true);
    }
  };

  const getWorkspaces = () => {
    setLoadingWorkspace(true);
    WorkspaceService.getWorkspaces()
      .then((resp) => {
        setWorkspaces(resp?.data);
      })
      .finally(() => setLoadingWorkspace(false));
  };

  const updateUserInfo = async (callback) => {
    const user = await UserService.getUserInfo();
    user && setUser(user.data);
    if (callback && isFunction(callback)) {
      callback();
    }
  };

  return {
    logOut,
    configureAuth,
    isLoggedIn,
    updateUserInfo,
    getWorkspaces,
    user,
    setUser,
    workspaces,
    setWorkspaces,
    loadingWorkspace,
  };
};

export default UserContext;
