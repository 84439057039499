import RestClient from './rest-client';
import { toaster } from 'evergreen-ui';
import { get } from 'utils/lodash.util';
import queryString from 'query-string';

const WORKSPACES_URL = '/workspaces';

const getWorkspaces = async (params) => {
  try {
    const response = await RestClient.get(`${WORKSPACES_URL}?${queryString.stringify(params)}`);
    return response.data;
  } catch (error) {
    if (get(error, ['response', 'data', 'statusCode']) !== 401 && typeof window !== 'undefined') {
      const errorMess = get(error, ['response', 'data', 'message']);
       errorMess && toaster?.danger(errorMess);
    }
  }
};

const getWorkspaceDetail = async (workspaceId) => {
  try {
    const response = await RestClient.get(`${WORKSPACES_URL}/${workspaceId}`);
    return response.data;
  } catch (error) {
    if (get(error, ['response', 'data', 'statusCode']) !== 401) {
      const errorMess = get(error, ['response', 'data', 'message']);
      typeof window !== 'undefined' && toaster.danger(errorMess);
    }
  }
};

const addWorkspaces = async (payload) => {
  try {
    const response = await RestClient.post(`${WORKSPACES_URL}`, payload);
    return response;
  } catch (error) {
    typeof window !== 'undefined' && toaster.danger(get(error, ['response', 'data', 'message']));
  }
};

const deleteWorkspace = async (workspaceId) => {
  try {
    const response = await RestClient.delete(`${WORKSPACES_URL}/${workspaceId}`);
    return response?.data;
  } catch (error) {
    typeof window !== 'undefined' && toaster.danger(get(error, ['response', 'data', 'message']));
  }
};

const updateWorkspace = async (workspaceId, payload) => {
  try {
    const response = await RestClient.put(`${WORKSPACES_URL}/${workspaceId}`, payload);
    return response;
  } catch (error) {
    typeof window !== 'undefined' && toaster.danger(get(error, ['response', 'data', 'message']));
  }
};

const WorkspaceService = {
  getWorkspaces,
  addWorkspaces,
  deleteWorkspace,
  updateWorkspace,
  getWorkspaceDetail,
};

export default WorkspaceService;
